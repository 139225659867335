article {
  @apply my-5 p-5 flex flex-col items-center;
}

article div {
  @apply my-1 p-1;

  width: 95%;
}

.image_container {
  @apply w-full flex flex-col flex-wrap items-center justify-center;
}

.image_container span {
  @apply w-full flex flex-row flex-wrap items-center justify-center;
}

.image_container img {
  @apply my-4 mx-4 p-0 max-h-96;
}

.title-lg {
  @apply sm:hidden absolute z-0;
  margin         : 2rem 0 0 2rem;
  padding        : 0.5rem 1rem;
  width          : 25rem;
  align-items    : flex-start;
  justify-content: left;
}

.title-sm {
  @apply md:hidden lg:hidden xl:hidden;
  margin: 1rem 0 1rem 0.5rem;
  width : calc(100% - 0.5rem);
}
