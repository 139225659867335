div {
  @apply my-5 p-5 flex flex-col items-center;
}

.title {
  @apply my-8 w-11/12;
}

.address {
  @apply my-5 p-0 w-11/12 flex flex-col items-start;
}

.address p {
  @apply text-lg;
}

.map {
  @apply my-2 mx-0 p-0 w-11/12;
  height: 65vh;
}