*:focus {
  outline: none;
}

#root {
  @apply m-0 p-0 w-full h-full;
}

.above-tab {
  @apply sm:hidden inset-x-0 top-0 h-8 border-b-2 border-green-500 z-10;

  position        : fixed;
  color           : theme('colors.gray.800');
  background-color: theme('colors.gray.50');
}

.above-tab ul {
  @apply h-full flex flex-row justify-end list-none;
}

.above-tab li {
  @apply px-2 text-xl;
}

.above-tab li:hover {
  color           : theme('colors.gray.50');
  background-color: theme('colors.green.500');
}

.below-tab {
  @apply md:hidden lg:hidden xl:hidden py-2 inset-x-0 bottom-0;

  position        : fixed;
  background-color: theme('colors.green.500');
}

.below-tab ul {
  @apply flex flex-row justify-center items-center list-none;
}

.below-tab li {
  @apply px-2 text-xs flex flex-col flex-1 justify-center items-center;
}

.below-tab-item {
  @apply flex flex-col justify-center items-center;
}

.below-tab-icon {
  @apply h-7 w-7 text-gray-500;
}

.main {
  @apply mt-0 mb-16 mx-0 px-0 py-1 w-full h-auto flex flex-col justify-center;
}
